import React from "react";
import Button from "../generics/Button";

const HomeEmpresaDesenvolvimento = () => {
  return (
    <div className="md:max-w-[1370px] md:mx-auto mx-[4vw] md:px-[3vw]">
      {/* Container principal */}
      <div className="flex flex-col md:flex-row justify-between items-start space-y-6 md:space-y-0 md:space-x-12 text-center md:text-left">
        {/* Texto à esquerda no desktop, centralizado no mobile */}
        <div className="w-full md:w-6/12 space-y-4">
          <h1 className="text-3xl md:text-5xl text-white font-regular font-secondary">
            Empresa de <span className="text-glitter font-secondary font-bold">Desenvolvimento </span> de Software <div className="hidden md:block">personalizado com ênfase em <span className="text-glitter font-secondary font-bold">inovação.</span></div><span className="text-glitter font-secondary font-bold md:hidden">sob demanda.</span>
          </h1>

          <p className=" text-md md:text-lg text-frost font-medium md:font-regular font-secondary">
            Descubra na Concatech a sinergia entre sua visão e nossa tecnologia avançada, moldando o futuro com software sob medida. Somos sua parceria ideal em inovação e soluções tecnológicas personalizadas.
          </p>
          <div className="flex justify-center md:justify-start">
            <Button text="Entrar em Contato" to="/contato" color="bg-white" icon={true} />
          </div>
        </div>

        {/* Espaço vazio à direita no desktop */}
        <div className="hidden md:block md:w-1/3"></div>
      </div>
    </div>
  );
};

export default HomeEmpresaDesenvolvimento;
