import React from "react";

const HomeFases = () => {
  return (
    <section id="sobre">
      <div className="md:max-w-[1370px] md:mx-auto mx-[4vw] md:px-[3vw]">
        <div className="h-[760px] md:h-[400px]">
          <div class="flex flex-col md:flex-row md:justify-between space-y-[6vw] md:space-y-[3vw] md:space-y-0 md:space-x-[3vw] md:min-h-[350px]">

            <div
              className="relative md:basis-1/3 bg-cover bg-center flex flex-col items-center justify-center text-void font-secondary p-2"
              style={{ backgroundImage: "url('/assets/discovery-bg.svg')", height: "350px" }}
            >
              {/* Caixa azul posicionada a 70% da altura */}
              <div className="absolute top-[25%] transform -translate-y-1/2 bg-opulent font-bold px-4 py-3 rounded-lg flex gap-4 items-center">
                {/* Ícone acima */}
                <span className="text-lg text-frost font-secondary flex items-center">Discovery de Projeto</span>
                <div className="font-secondary">  <img src="/assets/compass-03.svg" alt="Check" className="w-full h-auto" /></div>
              </div>

              {/* Espaço extra para não sobrepor */}
              <div className="mt-[150px] mx-8 md:mx-10 text-center">
                {/* Título principal */}
                <div className="text-xl-semibold md:text-2xl text-void font-semibold">Discovery</div>

                {/* Subtítulo abaixo do "Discovery" */}
                <p className="mt-2 opacity-70 text-sm font-regular font-secondary">Planejamento e execução de design para soluções digitais, desde a concepção até a entrega, com foco em inovação e usabilidade.</p>
              </div>
            </div>
            <div
              className="relative md:basis-2/3 bg-cover bg-center flex flex-col items-center justify-center text-2xl text-void font-semibold font-secondary text-center"
              style={{ backgroundImage: "url('/assets/design-bg.svg')", height: "350px" }}
            >

              {/* Caixa azul posicionada a 70% da altura */}
              <div className="absolute top-[38%] transform  -translate-y-1/2 bg-opulent min-w-[70%] md:min-w-[55%] px-4 py-2 md:px-4 md:py-3 rounded-lg flex justify-center">
                {/* Ícone acima */}
                <span className="text-xs md:text-lg text-frost font-regular">World Class Design</span>
              </div>


              {/* Espaço extra para não sobrepor */}
              <div className="mt-[200px] mx-8 md:mx-10 text-center">
                {/* Título principal */}
                <div className="text-xl-semibold md:text-2xl text-void font-semibold">Design</div>

                {/* Subtítulo abaixo do "Discovery" */}
                <p className="mt-2 opacity-70 text-sm font-regular font-secondary">Criação de experiências digitais imersivas para todas as plataformas, com um design UI/UX que destaca e personaliza a sua visão.</p>
              </div>
            </div>
          
          </div>
        </div>

        <div className="h-[790px] md:h-[400px]">
          <div class="flex flex-col md:flex-row md:justify-between space-y-[6vw] md:space-y-[3vw] md:space-y-0 md:space-x-[3vw] md:min-h-[350px]">

            <div
              className="relative md:basis-2/3 bg-cover bg-center flex flex-col items-center justify-center text-2xl text-void font-semibold font-secondary text-center"
              style={{ backgroundImage: "url('/assets/desenvolvimento-bg.svg')", height: "350px"  }}
            >

              {/* Caixa azul posicionada a 70% da altura */}
              <div className="absolute top-[48%] transform  -translate-y-1/2 bg-opulent min-w-[70%] md:min-w-[55%] px-4 py-2 md:px-4 md:py-3 rounded-lg flex justify-center">
                {/* Ícone acima */}
                <span className="text-xs md:text-lg text-frost font-regular">{"</ /concatech, {start} ‘new project’"}></span>
              </div>
              {/* Espaço extra para não sobrepor */}
              <div className="mt-[-190px] mx-8 md:mx-10 text-center">
                {/* Título principal */}
                <div className="text-xl-semibold md:text-2xl text-void font-semibold">Desenvolvimento</div>

                {/* Subtítulo abaixo do "Discovery" */}
                <p className="mt-2 opacity-70 text-sm font-regular font-secondary">Desenvolvimento de software ágil que transforma ideias em realidade, com foco em resultados e na satisfação do cliente.</p>
              </div>

            </div>
            <div
              className="relative md:basis-1/3 bg-cover bg-center flex flex-col items-center justify-center text-2xl text-void font-semibold font-secondary text-center"
              style={{ backgroundImage: "url('/assets/validacao-bg.svg')", height: "350px"  }}
            >
              {/* Caixa azul posicionada a 70% da altura */}
             <div className="absolute top-[40%] transform -translate-y-1/2 bg-opulent min-w-[70%] md:min-w-[55%] px-4 py-2 md:px-4 md:py-3 gap-2 rounded-lg flex items-center justify-center">
               {/* Ícone acima */}
               <div className="font-secondary">
                 <img src="/assets/check-circle.svg" alt="Check" className="w-full h-auto" />
               </div>
               
               <span className="text-lg text-frost font-secondary">Pagina Inicial</span>
               
               {/* Círculo verde */}
               <div className="rounded-full bg-green h-2 w-2 flex items-center"></div>
               
               <span className="text-xs md:text-lg text-frost font-regular">online</span>
             </div>

              {/* Espaço extra para não sobrepor */}
              <div className="mt-[190px] mx-8 md:mx-10 text-center">
                {/* Título principal */}
                <div className="text-xl-semibold md:text-2xl text-void font-semibold">Validação</div>

                {/* Subtítulo abaixo do "Discovery" */}
                <p className="mt-2 opacity-70 text-sm font-regular font-secondary">Nessa fase, ajustamos e ponderamos detalhes para garantir que o produto final seja fiel aos objetivos estabelecidos. Asseguramos que cada projeto atenda os mais altos padrão.</p>
              </div>
            </div>

          </div>
        </div>

      </div>
    </section>

  );
};

export default HomeFases;
