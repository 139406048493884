import React from "react";
import Button from "../generics/Button";

const contactCards = [
  {
    icon: "/assets/email.svg",
    title: "Email",
    description: "Fale com a nossa equipe de especialistas por e-mail e receba um atendimento completo e personalizado para tirar suas dúvidas ou iniciar um projeto.",
    info: "contato@concatech.com.br",
    buttonText: "Enviar email",
    link: "mailto:contato@concatech.com.br",
  },
  {
    icon: "/assets/whatsapp.svg",
    title: "Whatsapp",
    description: "Converse diretamente com nosso time pelo WhatsApp. Estamos prontos para responder rapidamente e oferecer soluções sob medida para o seu negócio.",
    info: "+55 51 994525854",
    buttonText: "Enviar mensagem",
    link: "https://wa.me/5551994525854",
  },
  {
    icon: "/assets/instagram.svg",
    title: "Instagram",
    description: "Acompanhe nossas redes sociais e fique por dentro de novidades, bastidores, projetos e conteúdos sobre tecnologia, inovação e desenvolvimento de software.",
    info: "@concatech",
    buttonText: "Seguir @concatech",
    link: "https://instagram.com/concatech.oficial",
  },
];

const ContatoCards = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-5xl mx-auto px-4 py-12">
      {contactCards.map((card, index) => (
        <div
          key={index}
          className=" rounded-[20px] border border-frost p-6 flex flex-col items-center text-center"
        >
          <img src={card.icon} alt={card.title} className="w-10 h-10 mb-4" />
          <h2 className="text-lg text-void font-bold font-secondary mb-2">{card.title}</h2>
          <p className="text-sm text-void text-opacity-70 mb-2 font-secondary">
            {card.description}
          </p>
          <p className="text-sm text-void font-secondary mb-6">{card.info}</p>
         <Button border={true} color={"bg-white"} external={true} text={card.buttonText} icon={true}  to={card.link}/>
        </div>
      ))}
    </div>
  );
};

export default ContatoCards;
