const ProjectIntroduction = () => {
  return (
    <div className="">
          <div className="break-inside-avoidbg-green text-center space-y-5 rounded-lg shadow-md">
            <div className="bg-gray p-[4vw]">
                <span className="inline-flex items-center rounded-md bg-blue px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-gray-500/10">
                  Badge
                </span>
                <h1 className="text-2xl text-void font-bold font-secondary text-center md:text-4xl text-void font-bold font-secondary text-center">
                  Reduzz energy
                </h1>
              <p className="text-md text-void font-regular font-secondary text-center opacity-70 mt-4 md:text-lg ">
                Descrição de serviço
              </p>
            </div>
            <div className={`bg-gray w-full h-[30vw] rounded-lg flex items-center justify-center`}>
              {/* Placeholder para a imagem */}
              Imagem
            </div>            

          </div>
    </div>
  );
};

export default ProjectIntroduction;
