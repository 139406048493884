import React from 'react';

const ProjectVideoComponent = ({ videoId }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  return (
    <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-lg shadow-lg">
      <iframe 
        className="absolute top-0 left-0 w-full h-full" 
        src={embedUrl} 
        title="YouTube video player" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default ProjectVideoComponent;
