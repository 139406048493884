import React from "react";
import { Link } from "react-router-dom";
import IconText from "../generics/IconText";
import Button from "../generics/Button";

const ProjectText = () => {
  return (
      <div className="bg-green text-left justify-between items-center md:space-y-0 md:space-x-[4vw]">
            <div className="bg-gray md:mt-0 min-h-[55vw] md:min-h-[25vw] w-[100%] md:w-2/2 space-y-4">
                <h1 className="text-xl-semibold text-void font-semibold font-secondary md:text-3xl">
                  Short heading goes here                
                </h1>
                <p className="text-sm text-void font-regular font-secondary">
                  Na Concatech, sua necessidade define nossa abordagem. Como uma empresa de desenvolvimento de software líder, adaptamos nossos serviços para criar soluções que atendem exatamente ao que seu negócio precisa. De aplicativos móveis a sistemas complexos, nossa flexibilidade é a chave para o seu sucesso tecnológico.            
                </p>
                <p className="text-sm text-void font-regular font-secondary">
                  Na Concatech, sua necessidade define nossa abordagem. Como uma empresa de desenvolvimento de software líder, adaptamos nossos serviços para criar soluções que atendem exatamente ao que seu negócio precisa. De aplicativos móveis a sistemas complexos, nossa flexibilidade é a chave para o seu sucesso tecnológico.            
                </p>
                <p className="text-sm text-void font-regular font-secondary">
                  Na Concatech, sua necessidade define nossa abordagem. Como uma empresa de desenvolvimento de software líder, adaptamos nossos serviços para criar soluções que atendem exatamente ao que seu negócio precisa. De aplicativos móveis a sistemas complexos, nossa flexibilidade é a chave para o seu sucesso tecnológico.            
                </p>                
            </div>
        </div>
  );
};

export default ProjectText;
