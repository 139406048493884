import {lazy, React, Suspense} from "react";

import HomeEmpresaDesenvolvimento from "./HomeEmpresaDesenvolvimento"
import HomeInovacaoPersonalisacao from "./HomeInovacaoPersonalisacao"
import HomeComoFazemosParaEntregar from "./HomeComoFazemosParaEntregar"
import HomeDesenvolvimentoProjeto from "./HomeDesenvolvimentoProjeto"
import HomeVersatilidade from "./HomeVersatilidade"
import HomeEngagamentoEstrategico from "./HomeEngagamentoEstrategico"
import HomeRealizeSeuProjetoNaConcatech from "./HomeRealizeSeuProjetoNaConcatech"
import PerguntaFrequente from "./PerguntaFrequente"
import HomeFases from "./HomeFases";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const  HomeCarousel = lazy(() => import('./HomeCarousel'));
const  HomeExploraNossosProjetos =  lazy(() => import('./HomeExploraNossosProjetos'));

const Home = () => {
        const location = useLocation();

        useEffect(() => {
                if (location.hash) {
                        const target = document.querySelector(location.hash);
                        if (target) {
                                setTimeout(() => {
                                        target.scrollIntoView({ behavior: "smooth" });
                                }, 300); // pequeno delay para garantir que tudo renderizou
                        }
                }
        }, [location]);
        return (
                <>
                        <div className="bg-void pt-[4vw] md:pt-[3vw] pb-[10vw] md:pb-[3vw] bg-cover bg-center"
                                style={{
                                        backgroundImage: `
                                        radial-gradient(circle at top left, rgba(7, 13, 39, 1) 0%, transparent 100%),
                                        radial-gradient(circle at bottom left, rgba(7, 13, 39, 1) 0%, transparent 100%),
                                        radial-gradient(circle at bottom right, rgba(7, 13, 39, 1) 0%, transparent 100%),
                                        radial-gradient(circle at bottom right, rgba(7, 13, 39, 0) 100%, transparent 100%),

                                        url('/assets/home-bg.svg')
                                         `,
                                }}>
                                <div className=" pt-[20vw] md:pt-[6vw] pb-[10vw] md:pb-[3vw]">
                                        <HomeEmpresaDesenvolvimento />
                                </div>
                                <Suspense fallback={<div>...</div>}>
                                <div className=" pb-[15vw] md:pb-[2vw]">
                                        <HomeCarousel />
                                </div>
                                </Suspense>
                        </div>

                        <div className="pt-[10vw] md:pt-[3vw] pb-[10vw] md:pb-[5vw]">
                                <HomeInovacaoPersonalisacao />
                        </div>
                        <div className="md:pb-[3vw]">
                                <HomeFases />
                        </div>
                        <div className="pb-[10vw] md:pb-[3vw]">
                                <HomeComoFazemosParaEntregar />
                        </div>
                        <div className="pb-[10vw] md:pb-[3vw]">
                                <HomeDesenvolvimentoProjeto />
                        </div>
                        <div className="pb-[10vw] md:pb-[3vw]">
                                <HomeVersatilidade />
                        </div>
                        <div className="pb-[10vw] md:pb-[3vw]">
                                <HomeEngagamentoEstrategico />
                        </div>
                        <div className="pb-[10vw] md:pb-[3vw]">
                                <HomeRealizeSeuProjetoNaConcatech />
                        </div>
                        <Suspense fallback={<div>...</div>}>
                        <div className="pb-[10vw] md:pb-[3vw]">
                                <HomeExploraNossosProjetos />
                        </div>
                        </Suspense>
                        <div className="pb-[10vw] md:pb-[3vw]">
                                <PerguntaFrequente />
                        </div>
                </>
        );
};

export default Home;
