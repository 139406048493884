import React from "react";
import Button from "../generics/Button";

const HomeRealizeSeuProjetoNaConcatech = () => {
  return (
    <div className="md:mx-auto mx-[4vw] px-[2vw]  justify-center items-center  ">
      <div
        className="rounded-[32px] bg-cover bg-center md:max-w-[1530px] md:mx-auto flex flex-col  justify-center items-center   min-h-[22vw]"
        style={{ backgroundImage: "url('/assets/realize-bg.svg')" }}
      >
        {/* Ícone de chat dentro de um box quadrado */}

       
          <img
            src="/assets/chat-button.svg"
            alt="Chat"
            className="w-44 h-44 object-contain"
          />
       



        <h1 className="max-w-md text-2xl text-void font-regular font-secondary text-center md:text-4xl ">
          <span className="text-2xl-compact text-glitter font-bold font-secondary text-center md:text-4xl ">Realize seu projeto </span> com a Concatech
        </h1>
        <div className=" mt-[1.25rem]">
          <p className="max-w-2xl text-md text-void font-regular font-secondary text-center opacity-70 ">
            Dê o primeiro passo com a Concatech e veja sua ideia evoluir. Como uma empresa de desenvolvimento de software sob demanda, estamos prontos para entender e atender suas necessidades específicas. Entre em contato e vamos transformar sua visão em uma solução tecnológica inovadora.        </p>
        </div>

        <div className="mt-[2.25rem] mb-[5.50rem]">
        <Button text="Entrar em Contato" to="/contato" color="bg-opulent" icon={true} mdTextAlign="md:text-center" />
        </div>
      </div>
    </div>
  );
};

export default HomeRealizeSeuProjetoNaConcatech;
