import React from "react";

const steps = [
  {
    title: "Discovery",
    icon: "/assets/icon-discovery-timeline.svg",
    description:
      "Nessa etapa crucial de estudo e planejamento, mergulhamos na visão do cliente, mapeando necessidades e objetivos para criar uma estratégia eficaz.",
  },
  {
    title: "Design",
    icon: "/assets/icon-design-timeline.svg",
    description:
      "O design é essencial em nosso processo de desenvolvimento de software. Criamos interfaces intuitivas e experiências envolventes que encantam usuários e fortalecem marcas.",
  },
  {
    title: "Desenvolvimento",
    icon: "/assets/icon-desenvolvimento-timeline.svg",
    description:
      "Nossa abordagem de desenvolvimento transforma estratégias em soluções tangíveis, utilizando metodologias ágeis para entregar produtos que impulsionam o sucesso do cliente.",
  },
  {
    title: "Validação",
    icon: "/assets/icon-validacao-timeline.svg",
    description:
      "Garantimos a qualidade com testes rigorosos, validando cada aspecto da solução para entregar um produto confiável e de alto desempenho.",
  },
];

const HomeComoFazemosParaEntregar = () => {
  return (
    <div className="md:mx-auto  md:px-[3vw]">
      <div
        className="bg-cover bg-center md:max-w-[1530px] md:mx-auto md:rounded-[32px]"
        style={{ backgroundImage: "url('/assets/processos-bg.svg')" }}
      >
        {/* Título */}
        <div className="flex flex-col items-center text-left px-[1.5rem] md:px-[5rem] py-[5rem] md:py-[7.5rem] ">
          <p className="text-sm  md:text-md text-white font-regular font-secondary text-center mb-[1rem]">
            Como funciona nosso trabalho
          </p>

          <h1 className="text-2xl md:text-4xl text-white font-regular font-secondary text-center mb-[2.5rem] md:mb-[5rem] ">
            <span className="hidden md:inline-block flex-1"></span>
            Como fazemos para{" "}
            <span className="text-2xl md:text-4xl text-glitter font-bold font-secondary text-center">
              entregar tudo
            </span>
            <span className="hidden md:inline-block flex-1"></span>
          </h1>

          {/* TIMELINE COMPLETA */}
          <div className="hidden md:block  flex  justify-center">
            <div className="flex items-start justify-between w-full max-w-6xl space-x-4 relative">
              {/* Linha de conexão */}
              <div className="absolute top-[28px] h-[3px] left-0 right-0 h-px bg-[#43BCFF26] z-0" />

              {steps.map((step, index) => (
                <div
                  key={index}
                  className="relative z-10 flex flex-col items-center text-center max-w-[250px]"
                >
                  {/* Ícone dentro do círculo */}
                  <div className="flex items-center justify-center w-14 h-14 rounded-full border border-[3px] border-[#43BCFF26] bg-[#20304C] mb-4 z-10">
                    <img src={step.icon} alt={step.title} className="w-6 h-6" />
                  </div>

                  {/* Título */}
                  <h3 className="text-xl  text-center text-white font-semibold font-secondary text-md mb-[0.5rem]">
                    {step.title}
                  </h3>

                  {/* Descrição */}
                  <p className="  text-center text-frost opacity-70 text-sm font-regular font-secondary  leading-relaxed">
                    {step.description}
                  </p>
                </div>
              ))}
            </div>
          </div>

           {/* TIMELINE MOBILE */}
        <div className="flex flex-col space-y-12 md:hidden relative px-[4vw] pt-[0px] pb-[0px]">
          {/* Linha vertical de conexão */}
          <div className="absolute top-0 bottom-0 left-[44px] w-[3px] bg-[#43BCFF26] z-0" />

          {steps.map((step, index) => (
            <div key={index} className="relative z-10 flex items-start space-x-4">
              {/* Ícone */}
              <div className="flex-shrink-0 w-14 h-14 rounded-full border-[3px] border-[#43BCFF26] bg-[#20304C] flex items-center justify-center z-10">
                <img src={step.icon} alt={step.title} className="w-6 h-6" />
              </div>

              {/* Conteúdo */}
              <div>
                <h3 className="text-xl text-white font-semibold font-secondary mb-[0.5rem]">
                  {step.title}
                </h3>
                <p className="text-frost opacity-70 text-sm font-regular font-secondary leading-relaxed">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      
        </div>
      </div>
    </div>
  );
};

export default HomeComoFazemosParaEntregar;
