import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";
import Button from "../generics/Button";

const ContactForm = () => {
  const [accepted, setAccepted] = useState(false);
  const form = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!accepted) {
      toast.error("Você precisa aceitar os termos antes de enviar.");
      return;
    }

    toast
      .promise(
        emailjs.sendForm(
          "service_bg9h4qm",     // <-- Substitua
          "template_jf5p9q2",    // <-- Substitua
          form.current,
          "zKUKfcJXlFjUlNHip"      // <-- Substitua
        ),
        {
          loading: "Enviando mensagem...",
          success: "Mensagem enviada com sucesso!",
          error: "Erro ao enviar o formulário.",
        }
      )
      .then(() => {
        form.current.reset();
        setAccepted(false);
      });
  };

  return (
    <div className="px-4">
      <div className="max-w-xl mx-auto">
        <form ref={form} onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-x-6 gap-y-4 mb-5">
            {/* Campo Nome */}
            <div className="col-span-4">
              <label htmlFor="name" className="block text-sm font-medium">
                Name
              </label>
              <div className="mt-2 border border-voidLight border-opacity-10 rounded-md">
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Nome Completo..."
                  required
                  className="block w-full rounded-md bg-white px-3 py-1.5 text-sm text-void font-regular font-secondary"
                />
              </div>
            </div>

            {/* Campo Email */}
            <div className="col-span-4">
              <label htmlFor="email" className="block text-sm font-medium">
                Email
              </label>
              <div className="mt-2 border border-voidLight border-opacity-10 rounded-md">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="seuemail@gmail.com"
                  required
                  className="block w-full rounded-md bg-white px-3 py-1.5 text-sm text-void font-regular font-secondary"
                />
              </div>
            </div>

            {/* Campo Mensagem */}
            <div className="col-span-4">
              <label htmlFor="message" className="block text-sm font-medium">
                Mensagem
              </label>
              <div className="mt-2 border border-voidLight border-opacity-10 rounded-md">
                <textarea
                  id="message"
                  name="message"
                  rows={3}
                  placeholder="Escreva aqui sua mensagem..."
                  required
                  className="block w-full rounded-md bg-white px-3 py-1.5 text-sm text-void font-regular font-secondary outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline-2 focus:-outline-offset-2 focus:outline-glitter sm:text-sm"
                  defaultValue={""}
                />
              </div>
            </div>

            {/* Checkbox para aceitar os termos */}
            <div className="flex gap-3 items-center">
              <label htmlFor="termo" className="flex items-center gap-2 cursor-pointer font-secondary text-xs font-regular">
                <div className="group relative w-4 h-4 shrink-0">
                  <input
                    id="termo"
                    name="termo"
                    type="checkbox"
                    checked={accepted}
                    onChange={() => setAccepted(!accepted)}
                    className="absolute inset-0 appearance-none rounded-sm border border-voidLight bg-white checked:border-glitter checked:bg-glitter focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-glitter"
                  />
                  <svg
                    fill="none"
                    viewBox="0 0 14 14"
                    className="pointer-events-none w-3.5 h-3.5 absolute inset-0 m-auto stroke-white"
                  >
                    <path
                      d="M3 8L6 11L11 3.5"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className={accepted ? "opacity-100" : "opacity-0"}
                    />
                  </svg>
                </div>
                Eu aceito os{" "}
                <a
                  href="/termos"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xs-middle text-void font-regular font-secondary underline"
                >
                  Termos e Condições
                </a>{" "}
                
              </label>
            </div>
            </div>

            {/* Botão de envio */}
            <div className="flex justify-center items-center mt-12">
              <Button
                text="Entrar em Contato"
                submit={true}
                color="bg-opulent"
                mdTextAlign="md:text-center"
                icon={true}
              />
            </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
