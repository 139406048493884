import React from "react";
import { Link } from "react-router-dom"; // Importando Link para navegação entre páginas
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"; // Ícones para as redes sociais

const Footer = () => {
  return (
    <footer className="text-void">
      {/* Divider superior */}
      <div className=" mx-auto border border-voidLight border-solid border-l-0 border-r-0 border-opacity-10 py-8 md:py-8">
        <div className="mx-8 md:mx-12 mx-auto">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-6 sm:space-y-0 sm:space-x-4">
            {/* Logo Concatech */}
            <Link to="/" className="text-2xl font-bold">
              <img src="/assets/logo-full-black.svg" alt="Ícone Suporte 24/7" />
            </Link>

            {/* Links principais (centralizados em telas grandes e em coluna em dispositivos móveis) */}
            <div className="flex flex-col sm:flex-row items-center sm:space-x-6 text-sm md:text-xs space-y-4 sm:space-y-0">
              <Link to="/home" className="hover:text-void opacity-70">Home</Link>
              <Link to="/home#sobre" className="hover:text-void opacity-70">Sobre</Link>
              <Link to="/home#projetos" className="hover:text-void opacity-70">Projetos</Link>

              <Link to="/contato" className="hover:text-void opacity-70">Contato</Link>
            </div>

            {/* Ícones das redes sociais */}
            <div className="flex space-x-4">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebookF className="w-6 h-6 hover:text-glitter" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="w-6 h-6 hover:text-glitter" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FaLinkedinIn className="w-6 h-6 hover:text-glitter" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Divider inferior */}
      <div className="mx-8 md:mx-12 mx-auto  py-3 md:py-10">
        <div className=" mx-auto">
          <div className="flex flex-col sm:flex-row justify-between items-center text-xs space-y-4 sm:space-y-0 sm:space-x-6">
            <p>© 2022 Concatech. Todos os direitos reservados.</p>
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-6">
              {/* <Link to="/termos-de-uso" >Termos de Uso</Link>
              <Link to="/politicas-de-cookies" >Política de Cookies</Link>
              <Link to="/politica-de-privacidade" >Política de Privacidade</Link> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
