const HomeInovacaoPersonalisacao = () => {
  return (
    <div className="flex flex-col items-center text-center px-4 md:px-[22%]">
      {/* Título */}
      <h1 className="text-2xl  text-void font-regular font-secondary text-center md:text-4xl">
        <span className="hidden md:inline-block flex-1"></span>
        <span className="text-2xl text-glitter font-bold font-secondary text-center md:text-4xl">Inovação</span> e <span className="text-2xl text-glitter  font-bold font-secondary text-center md:text-4xl">Personalização</span> em Desenvolvimento de Software
        <span className="hidden md:inline-block flex-1"></span>
      </h1>

      {/* Subtítulo */}
      <p className="text-md text-void font-regular font-secondary text-center opacity-70 mt-[1vw]">
        Na Concatech, transformamos ideias inovadoras em soluções de software sob medida, 
        impulsionando o crescimento e a eficiência dos negócios com tecnologia de ponta.
      </p>
    </div>
  );
};

export default HomeInovacaoPersonalisacao;
