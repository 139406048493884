import React from "react";

const HomeVersatilidade = () => {
  return (
    <div className="md:mx-auto mx-[4vw] px-[2vw] md:px-[3vw]">
      <div
        className="rounded-[32px] bg-cover bg-center md:max-w-[1530px] md:mx-auto flex flex-col md:flex-row justify-center items-center md:space-y-0 md:space-x-[4vw] min-h-[22vw] py-[4vw]"
        style={{ backgroundImage: "url('/assets/versatilidade-bg.svg')" }}
      >
        <div className="flex flex-col md:pl-[4vw] md:pr-[4vw] justify-center items-center text-center md:text-left min-h-[55vw] md:min-h-[15vw] w-[100%] md:w-1/2 space-y-4">
          <h1 className="text-2xl md:text-4xl text-void font-regular font-secondary w-[100%]">
            <span className="text-2xl md:text-4xl text-glitter font-bold font-secondary">Versatilidade</span> em tecnologia
          </h1>
          <p className="text-sm md:text-md text-void font-regular font-secondary">
            Nossa empresa se destaca no desenvolvimento de software para um espectro amplo de setores. Entendemos as peculiaridades de cada área, fornecendo soluções precisas e inovadoras que impulsionam o sucesso e a eficiência em diversos campos de atuação.
          </p>
        </div>

        <div className=" text-sm text-void font-regular font-secondary text-center mt-4 flex flex-col items-center md:flex-row md:flex-wrap md:justify-start gap-3 min-h-[55vw] md:min-h-[0vw] w-full md:w-1/2">
        <div
            className="px-[16px] py-[10px] text-xs text-void font-regular font-secondary text-center opacity-70 rounded-[24px] border-[2px]"
            style={{
              background: "linear-gradient(88.36deg, #BDE7FF 0%, #DCF3FF 100%)",
              borderImageSource: " linear-gradient(180deg, #C9ECFF 0%, rgba(201, 236, 255, 0) 100%)",
            }}
          >Software de gestão empresarial</div>

          {/* ESTILO APLICADO AQUI */}
          <div
            className="px-[16px] py-[10px] text-xs text-void font-regular font-secondary text-center opacity-70 rounded-[24px] border-[2px]"
            style={{
              background: "linear-gradient(88.36deg, #BDE7FF 0%, #DCF3FF 100%)",
              borderImageSource: "linear-gradient(180deg, #C9ECFF 0%, rgba(201, 236, 255, 0) 100%)",
            }}
          >
            Aplicativos de conversas
          </div>

             <div
            className="px-[16px] py-[10px] text-xs text-void font-regular font-secondary text-center opacity-70 rounded-[24px] border-[2px]"
            style={{
              background: "linear-gradient(88.36deg, #BDE7FF 0%, #DCF3FF 100%)",
              borderImageSource: "linear-gradient(180deg, #C9ECFF 0%, rgba(201, 236, 255, 0) 100%)",
            }}
          >Software de gestão voltados para multinível</div>
             <div
            className="px-[16px] py-[10px] text-xs text-void font-regular font-secondary text-center opacity-70 rounded-[24px] border-[2px]"
            style={{
              background: "linear-gradient(88.36deg, #BDE7FF 0%, #DCF3FF 100%)",
              borderImageSource: "linear-gradient(180deg, #C9ECFF 0%, rgba(201, 236, 255, 0) 100%)",
            }}
          >Software de Finanças</div>
             <div
            className="px-[16px] py-[10px] text-xs text-void font-regular font-secondary text-center opacity-70 rounded-[24px] border-[2px]"
            style={{
              background: "linear-gradient(88.36deg, #BDE7FF 0%, #DCF3FF 100%)",
              borderImageSource: "linear-gradient(180deg, #C9ECFF 0%, rgba(201, 236, 255, 0) 100%)",
            }}
          >Plataforma de conexão entre pessoas</div>
             <div
            className="px-[16px] py-[10px] text-xs text-void font-regular font-secondary text-center opacity-70 rounded-[24px] border-[2px]"
            style={{
              background: "linear-gradient(88.36deg, #BDE7FF 0%, #DCF3FF 100%)",
              borderImageSource: "linear-gradient(180deg, #C9ECFF 0%, rgba(201, 236, 255, 0) 100%)",
            }}
          >Sites institucionais</div>
             <div
            className="px-[16px] py-[10px] text-xs text-void font-regular font-secondary text-center opacity-70 rounded-[24px] border-[2px]"
            style={{
              background: "linear-gradient(88.36deg, #BDE7FF 0%, #DCF3FF 100%)",
              borderImageSource: "linear-gradient(180deg, #C9ECFF 0%, rgba(201, 236, 255, 0) 100%)",
            }}
          >Software de gestão empresarial</div>
             <div
            className="px-[16px] py-[10px] text-xs text-void font-regular font-secondary text-center opacity-70 rounded-[24px] border-[2px]"
            style={{
              background: "linear-gradient(88.36deg, #BDE7FF 0%, #DCF3FF 100%)",
              borderImageSource: "linear-gradient(180deg, #C9ECFF 0%, rgba(201, 236, 255, 0) 100%)",
            }}
          >Aplicativos de Prestação de Serviço</div>
        </div>
      </div>
    </div>
  );
};

export default HomeVersatilidade;
