const ContactIntroduction = () => {
  return (
    <div className="max-w-2xl mx-auto">
          <div className="break-inside-avoid text-center space-y-5">
            <div className="p-[4vw]">
                <span className="text-2xl text-glitter font-bold font-secondary text-center  md:text-4xl ">Vamos conversar</span> <h1 className="text-2xl text-void font-semibold font-secondary text-center md:text-4xl text-void font-semibold font-secondary text-center inline">
                  sobre seu próximo projeto?
                </h1>
              <p className="text-lg text-void font-regular font-secondary text-center opacity-70 mt-4">
                Descubra na Concatech a sinergia entre sua visão e nossa tecnologia avançada, moldando o futuro com software sob medida. 
              </p>
            </div>
          </div>

    </div>
  );
};

export default ContactIntroduction;
