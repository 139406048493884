import React from "react";
import IconText from "../generics/IconText";
import Button from "../generics/Button";

const HomeEngagamentoEstrategico = () => {
  return (
    <div className="md:max-w-[1370px] justify-center items-center md:mx-auto mx-[4vw] md:px-[3vw] flex flex-col-reverse md:flex-row-reverse justify-between items-center md:space-y-0 md:gap-x-[4vw]">
      <div className="mt-5 md:mt-0 min-h-[55vw] md:min-h-[25vw] w-[100%] md:w-1/2 space-y-4">
        <p className="text-sm md:text-md text-void font-regular font-secondary text-center md:text-left">
          Solução
        </p>
        <h1 className="text-3xl md:text-4xl text-void font-regular font-secondary text-center md:text-left">
          <span className="text-glitter font-bold font-secondary">Engajamento</span> estratégico
        </h1>
        <p className="text-md md:text-lg text-void font-regular font-secondary text-center md:text-left">
          Nossa metodologia de discovery é a base para transformar suas ideias em realidade digital. Como sua parceira em desenvolvimento de software, mergulhamos profundamente para entender e elaborar a melhor estratégia que trará seu projeto à vida, garantindo soluções alinhadas e eficazes.
        </p>
        {/* O QUE VIER A SEGUIR TEM QUE VIRAR COMPONENTE */}
        <IconText title="Colaboração e Compreensão" subtitle="Entendemos suas ideias para oferecer soluções precisas e alinhadas com seus objetivos" />
        <IconText title="Análise Aprofundada" subtitle="Mergulhamos nas especificidades do seu negócio para desenhar um roteiro de desenvolvimento claro." />
        <IconText title="Estratégias Personalizadas" subtitle="Criamos um plano de ação detalhado que transforma sua visão em uma solução tecnológica concreta." />
        <Button text="Entrar em Contato" to="/contato" color="bg-opulent" icon={true}  />
      </div>
      <div
        className="bg-cover bg-center min-h-[82vw] md:min-h-[30vw] w-full md:w-1/2"
        style={{ backgroundImage: "url('/assets/solucao-img.svg')" }}
      >
        {/* imagem como background */}
      </div>
    </div>
  );
};

export default HomeEngagamentoEstrategico;
