import { SlArrowRightCircle } from "react-icons/sl";

function IconText({ title, subtitle }) {
  return (
    <div className="flex flex-row space-x-2">
       <div className="w-4/24">
  <img src="/assets/image-check.svg" alt="Check" className="w-full h-auto" />
</div>

        <div className="w-11/12  flex flex-col">
            <p className="text-sm text-void font-bold font-secondary">{title}</p>
            <p className="text-sm text-void font-regular font-secondary">{subtitle}</p>                   
        </div>
    </div>

  );
}

export default IconText;
