import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"; // Ícones de expandir/recolher

const faqData = [
  {
    question: "O que é a Concatech?",
    shortAnswer: "A Concatech é uma empresa especializada em desenvolvimento de software.",
    moreAnswers: [
      "Oferecemos soluções estratégicas para transformar ideias em realidade digital.",
      "Nosso foco é entregar produtos escaláveis e de alta qualidade."
    ],
  },
  {
    question: "Como funciona o processo de desenvolvimento?",
    shortAnswer: "Nosso processo inclui análise aprofundada, design e desenvolvimento.",
    moreAnswers: [
      "Cada etapa é personalizada para atender às necessidades do cliente.",
      "Garantimos suporte contínuo para o sucesso do projeto."
    ],
  },
  {
    question: "Quais tecnologias vocês utilizam?",
    shortAnswer: "Trabalhamos com React, Node.js, Python e outras tecnologias modernas.",
    moreAnswers: [
      "Utilizamos arquitetura escalável baseada em nuvem.",
      "Nossos sistemas são otimizados para desempenho e segurança."
    ],
  },
];

const PerguntaFrequente = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    // Toggling o índice da pergunta para expandir ou recolher
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="flex flex-col justify-center items-center md:px-10">
      {/* Título */}
      <div className="w-full md:w-3/4 lg:w-1/2 space-y-6 flex flex-col items-center text-center p-6 rounded-lg">
        <h1 className="text-2xl md:text-4xl text-void font-regular font-secondary text-center md:text-4xl">
          Perguntas <span className="text-glitter font-bold font-secondary text-center md:text-4xl">Frequentes</span>
        </h1>
        <p className="text-md md:text-lg text-void font-regular font-secondary text-center opacity-70">
        Tem alguma pergunta? Nós temos as respostas!
        Confira abaixo as dúvidas mais comuns sobre nossos serviços, processos e formas de atuação. Se não encontrar o que procura, entre em contato com a gente — será um prazer te ajudar!
        </p>
      </div>

      {/* FAQ */}
      <div className="mt-5 w-full md:w-3/4 lg:w-1/2 space-y-4">
        {faqData.map((faq, index) => (
          <div key={index} className="bg-white border border-voidLight border-solid border-1px border-opacity-10 rounded-lg p-4">
            {/* Pergunta + Ícone de Expandir */}
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleFAQ(index)}
            >
              <h2 className="text-sm text-void font-semibold font-secondary">{faq.question}</h2>
              {openIndex === index ? (
                <AiOutlineMinus className="w-6 h-6 text-opulent" />
              ) : (
                <AiOutlinePlus className="w-6 h-6 text-opulent" />
              )}
            </div>

            {/* Resposta curta (sempre visível) */}
            <div className="mt-2 text-sm text-void font-regular font-secondary opacity-70">
              <p>{faq.shortAnswer}</p>
            </div>

            {/* Mais respostas (aparecem quando a pergunta é clicada) */}
            {openIndex === index && (
              <ul className="mt-2 space-y-2 text-sm text-void font-regular font-secondary opacity-70">
                {faq.moreAnswers.map((answer, idx) => (
                  <li key={idx} className="border-l-4 border-opulent pl-2">
                    {answer}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PerguntaFrequente;
