import React from "react";
import IconText from "../generics/IconText";
import Button from "../generics/Button";

const HomeDesenvolvimentoProjeto = () => {
  return (
    <div className="md:max-w-[1370px] md:mx-auto mx-[4vw] md:px-[3vw] flex flex-col-reverse md:flex-row justify-between items-center md:space-y-0 md:space-x-[4vw]">
      <div className="mt-5 md:mt-0 min-h-[55vw] md:min-h-[25vw] w-[100%] md:w-1/2 space-y-4">
        <p className="text-sm md:text-md text-void font-regular font-secondary text-center md:text-left">
          Flexibilidade
        </p>
        <h1 className="hidden md:block text-3xl md:text-4xl text-void font-regular font-secondary text-center md:text-left">
          Desenvolvimento de <span className="text-3xl md:text-4xl text-glitter font-bold font-secondary hidden md:inline">projetos personalizados</span>  para todas as necessidades
        </h1>
        <h1 className="block md:hidden text-2xl text-void font-regular font-secondary text-center ">
          Projetos de <span className="text-2xl font-secondary text-center text-glitter font-bold font-secondary">sob demanda</span> para todas as necessidades
        </h1>
        <p className="text-md  text-void font-regular font-secondary text-center md:text-left opacity-70">
          Na Concatech, sua necessidade define nossa abordagem. Como uma empresa de desenvolvimento de software líder, adaptamos nossos serviços para criar soluções que atendem exatamente ao que seu negócio precisa. De aplicativos móveis a sistemas complexos, nossa flexibilidade é a chave para o seu sucesso tecnológico.
        </p>
        {/* O QUE VIER A SEGUIR TEM QUE VIRAR COMPONENTE */}
        <div className="space-y-4 ">
          <IconText title="Adaptabilidade a Projetos Únicos" subtitle="Flexíveis para atender demandas específicas" />
          <IconText title="Soluções Customizadas" subtitle="Cada software é criado para ser uma solução sob medida." />
          <IconText title="Tecnologia que Evolui com Você" subtitle="Desenvolvemos software que cresce junto ao seu negócio." />
        </div>
        <div className="mt-[1.25rem]">
          <Button text="Entrar em Contato" to="/contato" color="bg-opulent" icon={true} />
        </div>
      </div>

      <div
        className="bg-cover bg-center min-h-[100vw] md:min-h-[35vw] w-full md:w-1/2"
        style={{ backgroundImage: "url('/assets/flexibilidade-img.svg')" }}
      >
        {/* imagem como background */}
      </div>

    </div>
  );
};

export default HomeDesenvolvimentoProjeto;
