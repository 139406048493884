import {Routes, Route} from "react-router-dom"
import App from "./App"
import Navbar from "./components/NavBar"
import PerguntaFrequente from "./components/home/PerguntaFrequente"
import Footer from "./components/Footer"
import ScrollToTop from "./components/generics/ScrollTop"
import ProjectIntroduction from "./components/projects/ProjectIntroduction"
import ProjectText from "./components/projects/ProjectText"
import ProjectVideoComponent from "./components/projects/ProjectVideoComponent"
import ContactIntroduction from "./components/contact/ContactIntroduction"
import ContactForm from "./components/contact/ContactForm"
import Home from "./components/home/home"
import HomeRealizeSeuProjetoNaConcatech from "./components/home/HomeRealizeSeuProjetoNaConcatech"
import ContatoCards from "./components/contact/ContatoCards"


export default function Router() {
    return (
        <>
        <ScrollToTop />
        <Navbar/>
        <Routes>
            {/* HOME - MOMENTANEO ATÈ VIRAR COMPONENTE */}
            <Route path="/home" element={<Home/>}/>

            <Route path="/projetos" element={
                <>
                <div className="bg-blue h-[60px] md:h-[150px]"></div>
                <div className="bg-blue h-[60px] md:h-[150px]"></div>
                <ProjectIntroduction/>
                <div className="bg-blue h-[60px] md:h-[152px]"></div>
                <ProjectText/>
                <div className="bg-blue h-[60px] md:h-[150px]"></div>
                <ProjectVideoComponent />
                <div className="bg-blue h-[60px] md:h-[150px]"></div> 
                <ProjectText/>
                <HomeRealizeSeuProjetoNaConcatech/>
                <div className="bg-blue h-[60px] md:h-[150px]"></div>                
                </>
            } />
            <Route path="/contato" element={<>
                <div className="h-[70px] md:h-[100px]"></div>
                <ContactIntroduction/>
                <ContactForm/>
                <ContatoCards/>
                <div className="h-[50px]"></div>
                <PerguntaFrequente/>
                <div className="h-[50px]"></div>
            </>} />
            {/* <Route path="/test" element={<App />} /> */}
            <Route path="/*" element={<Home />} />
        </Routes>
        <Footer/>
        </>
    )
}