import { Link } from "react-router-dom";

function Button({ text, to, color, mdTextAlign, icon, imageButton, border, external, submit }) {
  const isWhite = color === "bg-white";
  const isOpulent = color === "bg-opulent";

  const baseBorder = border ? "border border-voidLight border-solid border-1px border-opacity-10" : "border-none";
  const hoverBorder = isOpulent ? "hover:border hover:border-voidLight hover:border-solid hover:border-1px hover:border-opacity-10" : "";

  const buttonClasses = `
    ${color}
    ${isWhite ? "text-void" : "text-white"}
    ${baseBorder}
    ${hoverBorder}
    font-secondary font-semibold text-xs
    px-[14px] py-[6px] rounded-[10px]
    transition duration-300
    items-center justify-center gap-4
    ${isWhite ? "hover:bg-opulent hover:text-white" : isOpulent ? "hover:bg-white hover:text-void" : "hover:bg-blue-600"}
  `;

  const content = (
    <>
      {text}
      <div className="inline m-1"></div>
      {icon && (
        <img
          src={`/assets/${imageButton || "arrow-icon-button.svg"}`}
          alt="Ícone seta"
          className="w-6 h-6 inline"
        />
      )}
    </>
  );

  return (
    <div className={`text-center ${mdTextAlign ? mdTextAlign : "md:text-left"}`}>
      {submit ? (
        <button type="submit" className={buttonClasses}>
          {content}
        </button>
      ) : external ? (
        <a href={to} target="_blank" rel="noopener noreferrer">
          <button className={buttonClasses}>{content}</button>
        </a>
      ) : (
        <Link to={to}>
          <button className={buttonClasses}>{content}</button>
        </Link>
      )}
    </div>
  );
}

export default Button;
